
.course-box {
  padding: 30px 30px 0;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .operate-box {
    text-align: right;
  }
  .comment {
    padding: 0 8px;
    line-height: 30px;
    &:hover {
      background: #dcdfe6;
      cursor: pointer;
    }
  }
}
::v-deep .customTable {
  .el-table .cell div {
    display: inline-block;
  }
  .has-gutter .el-table__cell {
    color: #332d42 !important;
    background-color: #f0f3fc !important;
  }
  .cell {
    //line-height: normal;
    //white-space: nowrap;
  }
  .el-table__empty-block {
    display: none;
  }
}
